import React, { FC } from 'react';
import classNames from 'classnames';
import { ButtonProps } from './Button.types';
import './Button.scss';

const Button: FC<ButtonProps> = ({ children, tag, flat =  false, fullWidth = false, href, className, outlined , ...props }) => {

  return (
    <>
      {
        React.createElement(
          tag,
          {
            className: classNames('react-btn', className, {
              'react-btn--flat': flat,
              'react-btn--full-width': fullWidth,
              'react-btn--outlined': outlined
            }),
            href: href,
            type: tag === 'button' && props.type === undefined ? 'button' : props.type,
            ...props,
          },
          <>
            {children}
          </>,
        )}
    </>
  );
}

export default Button;
